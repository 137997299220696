<template>
  <div class="popup-container">
    <div class="issue-item">
      Log work for <strong>{{ jiraIssueKey }}</strong>
    </div>
    <div class="wbs-breadcum">
      <span v-for="item in breadcrumbList" :key="item.id">
        <a @click="getWbsInfo(item.id)">{{ item.title }} > </a>
      </span>
    </div>
    <b-field label="Wbs" label-position="on-border">
      <b-select v-model="wbs" rounded required expanded>
        <option v-for="item in wbsList" :key="item.id" :value="item">
          {{ item.title }}
        </option>
      </b-select>
    </b-field>
    <b-field label="Project" label-position="on-border" expanded>
      <b-autocomplete
        v-model="project"
        @select="(option) => (projectSelected = option)"
        :data="projectList"
        field="title"
        icon="magnify"
        clearable
        @typing="debouncedOnChange"
        open-on-focus
        rounded
        placeholder="Please input at least 2 character for searching"
        required >
        <template #empty>No results found</template>
      </b-autocomplete>
    </b-field>
    <b-field label="Site" label-position="on-border">
      <b-select v-model="site" :disabled="!project" rounded required expanded>
        <option v-for="item in siteList" :key="item.id" :value="item">
          {{ item.title }}
        </option>
      </b-select>
    </b-field>
    <b-field label="Technology Domain" label-position="on-border" v-show="showTechnologyDomain" expaned>
      <b-autocomplete
        v-model="domain"
        @select="(option) => (domainSelected = option)"
        :data="domainList"
        group-field="name"
        group-options="itemList"
        open-on-focus
        rounded
        required />
    </b-field>
    <b-field label="Date" label-position="on-border" expanded>
      <b-datepicker v-model="date" :unselectable-dates="unselectableDates" rounded />
    </b-field>
    <b-field label="Time Spent" label-position="on-border" required expanded>
      <b-input 
        v-model="timeSpent" 
        rounded 
        placeholder="Enter Hour and Minute, default is hour (1h 30m = 1.5 = 1.5h = 90m)"
        required />
    </b-field>
    <b-field label="Description" label-position="on-border" expaned>
      <b-input type="textarea" v-model="description" />
    </b-field>
    <b-field position="is-centered">
      <b-button label="Close" @click="closeDialog" /> &nbsp;
      <b-button :loading="showLoadingSaveButton" :disabled="disabledSaveButton" label="Save" type="is-info" @click="logwork" />
    </b-field>
  </div>
</template>

<script>
import _debounce from 'lodash.debounce';
import { createUUID } from "@/utils/common";
import {
  getCurrentWbs,
  getWbsList,
  getProjectList,
  getProjectListByProjectId,
  getSiteList,
  getTechnologyDomainList,
  saveWorkLog,
  getWorkLogList,
} from "@/api/ibiza";

export default {
  data() {
    return {
      wbs: undefined,
      project: undefined,
      site: undefined,
      domain: undefined,
      date: new Date(),
      timeSpent: undefined,
      description: undefined,
      projectSelected: undefined,
      domainSelected: undefined,
      defaultWbs: "N2-3-2-1",
      wbsList: [],
      projectList: [],
      siteList: [],
      domainList: [],
      domainMap: new Map(),
      domainIdMap: new Map(),
      showTechnologyDomain: true,
      currentUserId: undefined,
      jiraIssueKey: undefined,
      editWorklogId: undefined,
      isEditWorklogMode: false,
      breadcrumbList: [],
      showLoadingSaveButton: false,
      now: new Date().getTime()
    };
  },
  computed: {
    debouncedOnChange() {
      return _debounce(this.onlySearchProjectWhileTypeAtLeast2Character, 500);
    },
    disabledSaveButton() {
      return !this.wbs || !this.project || !this.timeSpent
    }
  },
  created() {
    this.onInit();
  },
  watch: {
    wbs: function () {
      this.onWbsSelected();
    },
    projectSelected: function () {
      this.onProjectSelected();
    },
  },
  methods: {
    async onInit() {
      await this.getJiraInfo();
      await this.getWbsInfo(this.defaultWbs);
      await this.getDomainList();
      await this.getWorklogDataWhenEdit();
    },
    async getWorklogDataWhenEdit() {
      AP.dialog.getCustomData((worklogEdit) => {
        this.fillEditWorkLogToForm(worklogEdit);
      });
    },
    async logwork() {
      if (this.isValidData()) {
        this.showLoadingSaveButton = true;
        const formData = this.createFormData();
        const response = await saveWorkLog(formData);
        this.showSubmitResult(response);
      }
    },
    showSubmitResult(response) {
      this.showLoadingSaveButton = false;
      if (response.data && response.data.result) {
        this.closeDialog();
      } else {
        if (response.data.errorDescription) {
          this.createPopupError(response.data.errorDescription)
        }
      }
    },
    async getJiraInfo() {
      try {
        await this.getIssueInfo();
        await this.getCurrentUser();
      } catch (e) {
        console.log(e);
      }
    },
    async getIssueInfo() {
      const response = await AP.context.getContext();
      if (response.jira.issue) {
        this.jiraIssueKey = response.jira.issue.key;
      }
    },
    createFormData() {
      const formData = new FormData();
      formData.append("option", this.isEditWorklogMode ? "update" : "insert");
      formData.append("wbscode", this.wbs.id);
      formData.append("PJTList", this.projectSelected.id);
      formData.append("PJTSites", this.createPJTSitesObject());
      formData.append("technologyDomainId", this.getSelectedDomainId());
      formData.append("user_id", this.currentUserId);
      formData.append("issueID", this.jiraIssueKey);
      formData.append("description", this.description ? this.description : "");
      formData.append("date", this.convertDateToYYYYMMDD(this.date));
      formData.append("worklogID", this.isEditWorklogMode ? this.editWorklogId : createUUID());
      formData.append("time", this.calculateTimeSpent());
      return formData;
    },
    async fillEditWorkLogToForm(worklogEdit) {
      if (worklogEdit) {
        this.fillWorkLogFormEditMode(worklogEdit);
      } else {
        this.fillWorkLogFormCreateMode();
      }
    },
    async fillWorkLogFormCreateMode() {
      this.isEditWorklogMode = false;
      this.getPreviousWorkLogToFillIntoForm();
    },
    async getPreviousWorkLogToFillIntoForm() {
      const response = await getWorkLogList(this.jiraIssueKey);
      if (response && response.data && response.data.data) {
        const list = response.data.data;
        const data = list[list.length - 1];
        this.fillWorkLogForm(data);
      }
    },
    async fillWorkLogFormEditMode(worklogEdit) {
      this.fillWorkLogForm(worklogEdit);
      this.timeSpent = worklogEdit.time + 'm';
      this.description = worklogEdit.description;
      this.date = new Date(worklogEdit.date);
      this.editWorklogId = worklogEdit.worklogID;
      this.isEditWorklogMode = true;
    },
    async fillWorkLogForm(worklogEdit) {
      const response = await getProjectListByProjectId(worklogEdit.PJTList);
      this.projectList = response.data;
      this.projectSelected = this.projectList[0];
      this.project = this.projectSelected.title;
      this.wbs = this.wbsList.find(w => w.id === worklogEdit.wbscode);
      const findDomain = this.domainIdMap.get(worklogEdit.technologyDomainId);
      this.domainSelected = findDomain.name;
      this.domain = findDomain.name;
    },
    createPJTSitesObject() {
      const obj = {
        "project": this.projectSelected.id,
        "site": this.site.id
      }
      return JSON.stringify(obj);
    },
    async getCurrentUser() {
      AP.user.getCurrentUser((user) => {
        if (user) {
          this.currentUserId = user.atlassianAccountId;
        }
      });
    },
    unselectableDates(day) {
      return this.now < day.getTime();
    },
    async getWbsInfo(wbs) {
      await this.getWbsList(wbs);
      await this.getWbsBreadcrumbs(wbs);
    },
    async getWbsList(wbs) {
      const response = await getWbsList(wbs);
      this.wbsList = response.data;
    },
    async getWbsBreadcrumbs(wbs) {
      this.breadcrumbList = [];
      if (wbs) {
        await this.getParentWbs(wbs);
      }
      this.addRootToBreadcrumbList();
    },
    async getParentWbs(wbs) {
      const response = await getCurrentWbs(wbs);
      const currentWbs = response.data[0];
      this.breadcrumbList.unshift(currentWbs);
      if (currentWbs.id.includes("-")) {
        const parentWbs = currentWbs.id.substring(0, currentWbs.id.length - 2);
        await this.getParentWbs(parentWbs);
      }
    },
    addRootToBreadcrumbList() {
      const rootWbs = {
        title: 'Root',
        id: '',
        dept: 1,
        haschildren: true,
        technicalDomain: false
      }
      this.breadcrumbList.unshift(rootWbs);
    },
    async getDomainList() {
      const response = await getTechnologyDomainList();
      this.domainList = this.convertDomainData(response.data);
    },
    convertDomainData(domains) {
      this.domainMap = new Map();
      domains.forEach((domain) => {
        this.domainMap.set(domain.name, domain);
        this.domainIdMap.set(domain.id, domain);
        if (domain.children) {
          let childList = [];
          domain.children.forEach((child) => {
            childList.push(child.name);
            this.domainMap.set(child.name, child);
            this.domainIdMap.set(child.id, child);
          });
          domain.itemList = childList;
        }
      });
      return domains;
    },
    onlySearchProjectWhileTypeAtLeast2Character() {
      if (!this.project || this.project.length < 2) {
        this.resetProjectAndSiteField();
      } else {
        this.getProjectData();
      }
    },
    resetProjectAndSiteField() {
      this.projectSelected = {};
      this.projectList = [];
      this.site = {};
      this.siteList = [];
    },
    async getProjectData() {
      const response = await getProjectList(this.project);
      this.projectList = response.data;
    },
    onProjectSelected() {
      if (this.projectSelected && this.projectSelected.id) {
        this.loadSiteByProject();
      }
    },
    async loadSiteByProject() {
      const response = await getSiteList(this.projectSelected.id);
      this.siteList = response.data;
      if (this.siteList) {
        this.site = this.siteList[0];
      }
    },
    createPopupError(message) {
      this.$buefy.dialog.alert({
          title: 'Error',
          message: message,
          type: 'is-danger'
      });
    },
    isValidData() {
      return (
        this.validateWbs() && this.validateProject() && this.validateTimeSpent()
      );
    },
    validateWbs() {
      if (!this.wbs) {
        this.createPopupError("Wbs is required!");
        return false;
      }
      return true;
    },
    validateProject() {
      if (!this.projectSelected) {
        this.createPopupError("Project is required!");
        return false;
      }
      return true;
    },
    validateTimeSpent() {
      if (!this.timeSpent) {
        this.createPopupError("Time Spent is required!");
        return false;
      } else {
        const time = this.timeSpent.trim();
        if (
          !time.endsWith("h") &&
          !time.endsWith("m") &&
          !time.endsWith("s") &&
          !this.endsWithNumber(time)
        ) {
          this.createPopupError("Time Spent should ends with (h, m, s)");
          return false;
        }
      }
      return true;
    },
    calculateTimeSpent() {
      let result = 0;
      const time = this.timeSpent.trim();
      const dataArray = time.split(" ");
      for (let i = 0; i < dataArray.length; i++) {
        const data = dataArray[i];
        const valueNumber = parseFloat(data.replace(/[^0-9.]/g, ""));
        if (this.endsWithNumber(data) || data.endsWith("h")) {
          result += this.convertHourToMinute(valueNumber);
        } else if (data.endsWith("m")) {
          result += valueNumber;
        } else if (data.endsWith("s")) {
          result += valueNumber / 60;
        }
      }
      return result;
    },
    getSelectedDomainId() {
      return this.domainMap.get(this.domainSelected)
        ? parseInt(this.domainMap.get(this.domainSelected).id)
        : undefined;
    },
    convertHourToMinute(hour) {
      return hour * 60;
    },
    endsWithNumber(string) {
      return string.match(/\d+$/) ? true : false;
    },
    convertDateToYYYYMMDD(date) {
      const d = date.getDate();
      const m = date.getMonth() + 1;
      const y = date.getFullYear();
      return y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d);
    },
    closeDialog() {
      AP.require("dialog", (dialog) => {
        dialog.close();
      });
    },
    async onWbsSelected() {
      if (this.wbs.haschildren) {
        await this.getWbsInfo(this.wbs.id);
      }
      this.showTechnologyDomain = this.wbs.technicalDomain;
    },
  },
};
</script>

<style scoped>
.issue-item {
  font-size: large;
  text-align: center;
  padding-bottom: 1rem;
}

.wbs-breadcum {
  font-size: smaller;
  padding-bottom: 0.5rem;
}

.popup-container {
  padding: 1rem;
}
</style>